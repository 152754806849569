<template>
  <div>
    <PreviewBanner v-if="$store.getters.preview">
      <span v-if="$store.getters.mode === 'test'">You're viewing a test subscription.</span>
      <span v-else>This is a live subscription, so please proceed with care.</span>
    </PreviewBanner>
    <div class="flex flex-col min-h-screen items-center bg-brand-light-sand space-y-10 px-5 py-12">
      <div class="w-full max-w-xl flex-grow flex flex-col justify-center items-center space-y-10">
        <!-- <img v-if="companyLogo" :src="companyLogo" class="max-w-12 max-h-12" /> -->
        <BrandImage :src="companyLogo" alignment="center" v-if="companyLogo" />
        <div v-if="!showStripeForm && !showSuccessScreen">
          <!-- WITH DISCOUNT -->
          <div v-if="hasDiscount" class="bg-white rounded-lg border-2 border-brand-sand p-8 text-center">
            <div class="mt-5 text-lg text-brand-black font-bold">Confirm your special offer below.</div>
            <p class="mt-5 text-brand-brown text-opacity-90 text-md font-medium leading-relaxed">
              Get a {{ discount.coupon | formatCouponAmount }} discount {{ discount.coupon | formatCouponDuration('for') }} by reactivating your subscription
              today ({{ amountAfterDiscount | formatAmount }} {{ price | formatInterval }} {{ discount.coupon | formatCouponDuration('for') }}).
            </p>
            <button
              class="mt-6 w-full p-3 rounded-md bg-client-primary hover:bg-opacity-90 transition-colors text-white font-bold disabled:opacity-70 disabled:cursor-default"
              @click="reactivate"
              :disabled="reactivationLoading"
            >
              <span v-if="reactivationLoading"><Loader /> Processing...</span>
              <span v-else>Confirm Offer</span>
            </button>
          </div>
          <!-- WITHOUT DISCOUNT -->
          <div v-else class="bg-white rounded-lg border-2 border-brand-sand p-8 text-center">
            <div class="mt-5 text-lg text-brand-black font-bold">We missed you!</div>
            <p class="mt-5 text-brand-brown text-opacity-90 text-md font-medium leading-relaxed">
              We'd love to have you back! Reactivate your subscription today for only {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }}.
            </p>
            <button
              class="mt-6 w-full p-3 rounded-md bg-client-primary hover:bg-opacity-90 transition-colors text-white font-bold disabled:opacity-70 disabled:cursor-default"
              @click="reactivate"
              :disabled="reactivationLoading"
            >
              <span v-if="reactivationLoading"><Loader /> Processing...</span>
              <span v-else>Reactivate</span>
            </button>
          </div>
          <p v-if="hasDiscount" class="text-brand-brown text-xs p-2 text-center leading-relaxed">
            When you reactivate your account, you’ll regain complete access at a discounted {{ amountAfterDiscount | formatAmount }}
            {{ price | formatInterval }}
            {{ discount.coupon | formatCouponDuration('for') }}
            <!-- Don't show the "then" if the coupon duration is forever -->
            <span v-if="discount.coupon.couponDuration"> then {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }} thereafter. </span>
          </p>
          <p v-else class="text-brand-brown text-xs p-2 text-center leading-relaxed">
            When you reactivate your account, you’ll regain complete access for {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }}.
          </p>
        </div>
        <div v-if="showStripeForm">
          <ReactivationStripeForm class="px-0 pt-0 md:bg-brand-light-sand" />
        </div>
        <div v-if="showSuccessScreen && !showStripeForm">
          <div class="bg-white rounded-lg border-2 border-brand-sand p-8 text-center">
            <div class="mt-5 text-lg text-brand-black font-bold">Done! We're so happy to have you back.</div>
            <p class="mt-5 text-brand-brown text-opacity-90 text-md font-medium leading-relaxed" v-if="hasDiscount">
              We just applied your {{ discount.coupon | formatCouponAmount }} discount {{ discount.coupon | formatCouponDuration('for') }} to your subscription.
              On behalf of the {{ companyName }} team, thank you!
            </p>
            <p v-else>On behalf of the {{ companyName }} team, thank you!</p>
            <button
              class="mt-6 w-full p-3 rounded-md bg-client-primary hover:bg-opacity-90 transition-colors text-white font-bold disabled:opacity-70 disabled:cursor-default"
              @click="redirect"
            >
              <span>Continue to {{ companyName }}</span>
            </button>
          </div>
          <p v-if="hasDiscount" class="text-brand-brown text-xs p-2 text-center leading-relaxed">
            Your card on file will be charged {{ amountAfterDiscount | formatAmount }} {{ price | formatInterval }}
            {{ discount.coupon | formatCouponDuration('for') }}
            <!-- Don't show the "then" if the coupon duration is forever -->
            <span v-if="discount.coupon.couponDuration"> then {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }} thereafter. </span>
          </p>
          <p v-else class="text-brand-brown text-xs p-2 text-center leading-relaxed">
            Your card on file will be charged {{ amountBeforeDiscount | formatAmount }} {{ price | formatInterval }}.
          </p>
        </div>
      </div>
      <div class="flex-none">
        <div v-if="showWatermark">
          <Watermark message="Offer Guaranteed by Churnkey" product="re" />
        </div>
        <div v-else>
          <p class="text-brand-brown text-sm font-medium">
            Questions?
            <a :href="redirectLink" target="_blank">Contact Us.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import EventBus from '@/helpers/event-bus';
import * as Filters from '@/helpers/filters';
import ReactivationStripeForm from '@/components/ReactivationStripeForm.vue';
import Loader from '@/components/ButtonLoader.vue';
import OrgService from '@/api/org.service';
import { getIntegerCurrencyString } from '@/helpers/currency-utils';
import Watermark from '@/components/Watermark.vue';
import PreviewBanner from '@/components/PreviewBanner.vue';
import BrandImage from '@/components/BrandImage.vue';

dayjs.extend(relativeTime);

export default {
  name: 'reactivate',
  components: {
    ReactivationStripeForm,
    Loader,
    Watermark,
    PreviewBanner,
    BrandImage,
  },
  data() {
    return {
      submitted: false,
      reactivationLoading: false,
      reactivationSuccess: null,
    };
  },
  mounted() {
    this.$i18n.locale = navigator.language;
    EventBus.$on('alert', (title, msg) => this.launchAlert(title, msg));
    EventBus.$on('reactivated', (data) => this.subReactivated(data));
  },
  computed: {
    showStripeForm() {
      // show if no default payment method or if submitted and failed
      return !this.hasDefaultPaymentMethod || (this.submitted && this.reactivationSuccess === false);
    },
    showSuccessScreen() {
      return this.submitted && this.reactivationSuccess;
    },
    showWatermark() {
      const orgShowsWatermark = this.account?.branding?.showWatermark;
      return orgShowsWatermark && this.showSuccessScreen;
    },
    account() {
      return this.$store.state?.account;
    },
    discount() {
      const discount = this.account?.dunning?.displayDiscount;
      if (!discount) return null;
      const { coupon, ...theRest } = discount;
      const currency = this.currency; // eslint-disable-line
      const updatedDiscount = {
        coupon: {
          ...coupon,
          currency,
        },
        ...theRest,
      };
      return updatedDiscount;
    },
    amountBeforeDiscount() {
      return this.discount?.amountBeforeDiscount;
    },
    amountAfterDiscount() {
      return this.discount?.amountAfterDiscount;
    },
    hasDiscount() {
      return !!this.account?.dunning?.displayDiscount?.coupon;
    },
    customerName() {
      return this.account?.name || this.account?.email;
    },
    defaultPaymentMethod() {
      return this.account?.defaultPaymentMethod || this.account?.defaultSource;
    },
    hasDefaultPaymentMethod() {
      return !!this.defaultPaymentMethod;
    },
    defaultPaymentMethodIsCard() {
      return this.defaultPaymentMethod?.type === 'card';
    },
    cardLast4() {
      return this.defaultPaymentMethod?.card?.last4 || null;
    },
    cardBrand() {
      return this.defaultPaymentMethod?.card?.brand || null;
    },
    canceledSubscription() {
      return this.account.dunning.subscription;
    },
    currency() {
      if (!this.canceledSubscription) return null;
      return this.canceledSubscription.currency;
    },
    items() {
      if (this.canceledSubscription) {
        return this.canceledSubscription.subscription_items;
      }
      return null;
    },
    item() {
      let item;
      if (this.items && Array.isArray(this.items) && this.items.length > 0) {
        item = this.items[0];
        return item;
      }
      return null;
    },
    price() {
      if (this.item?.price) {
        return this.item.price;
      }
      return null;
    },
    companyName() {
      return this.account?.orgName;
    },
    companyLogo() {
      return this.account.branding?.brandImage;
    },
    redirectLink() {
      let link = this.account?.dunning?.checkoutPage?.redirectLink;
      if (link && link.indexOf('://') === -1) {
        link = `https://${link}`;
      }
      return link;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    errorContext() {
      return this.$store.state.account;
    },
  },
  methods: {
    redirect() {
      // navigate to the org's website
      window.location.href = this.redirectLink;
    },
    async reactivate() {
      if (this.reactivationLoading) {
        console.log('Already processing!');
        return;
      }

      /*
      const previewMode = false;
      if (previewMode) {
        console.log('PREVIEW MODE: No subscription reactivation will be processed.');
        this.subReactivated({ success: true });
        return;
      }
      */

      const { subId } = this.canceledSubscription;
      this.reactivationLoading = true;
      this.submitted = true;
      const data = await OrgService.reactivate({ subscriptionId: subId });

      // delay for demo purposes and mock a data result
      /*
      const data = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            success: false,
            message: "error msg!",
          });
        }, 4000);
      });
      */
      this.subReactivated(data);
    },
    // the function below is called from here if the 1-click reactivation is used, or through the EvenBus if from Stripe Elements
    async subReactivated(data) {
      this.submitted = true;
      this.reactivationLoading = false;
      this.reactivationSuccess = !!data?.success; // computed properties read this to decide what to show next
    },
  },
  filters: {
    formatInterval(price) {
      try {
        const { interval, intervalCount } = price;
        const separator = intervalCount === 1 ? 'per' : 'every';
        if (intervalCount > 1) {
          return `${separator} ${intervalCount} ${interval}s`;
        }
        return `${separator} ${interval}`;
      } catch (err) {
        // throw err;
        console.log('Error parsing price!', err.message);
      }
    },
    formatAmount(amount, currency) {
      return getIntegerCurrencyString(amount < 0 ? 0 : amount, currency, false);
    },
    formatCouponDuration: Filters.formatCouponDuration,
    formatCouponAmount: Filters.formatCouponAmount,
  },
};
</script>

<style lang="scss">
@import '@/assets/style.scss';
</style>
